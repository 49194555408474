import * as React from 'react';
import styled, { css } from 'styled-components';
import { unit } from '../styles';
import { Box } from './box';

export const Container = styled.div`
  position: relative;
`;

// Pardon the dust...

export const Layout = styled(Box)``;
Layout.defaultProps = { padding: [0, 2] };

export interface LayoutSectionProps {
  children: React.ReactNode;
  minWidth?: number;
  size?: number;
}

const LayoutSectionBase = ({
  minWidth,
  size,
  ...props
}: LayoutSectionProps) => <div {...props} />;

export const LayoutSection = styled(LayoutSectionBase)`
  padding: 0 ${unit(1)}px;

  ${(p) =>
    p.size &&
    css`
      flex: ${p.size};
    `};

  ${(p) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth}px;
    `};
`;

export const LayoutRow = styled(Box)`
  align-items: stretch;
  display: flex;
  margin: 0 -${unit(1)}px;
`;
