import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { SourceAppSettings } from './__generated__/types';
import { useAuthContext } from './auth-context';
import { Button } from './shared/components/button';
import { Container } from './shared/components/container';
import { LogoSkills } from './shared/components/logo';
import { Text } from './shared/components/text';
import { unit } from './shared/styles';
import { canAccessAdmin } from './shared/utils/auth';

type Props = {
  sourceApp?: SourceAppSettings;
};

export function Header({ sourceApp }: Props) {
  const history = useHistory();

  const { currentUser, oidcEnabled } = useAuthContext();

  return (
    <Root>
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <LogoSkills />{' '}
            <Text color="#888" weight="medium">
              Cyber Ranges
            </Text>
          </Link>
        </HeaderLogo>
        <HeaderContent>
          {canAccessAdmin(currentUser) && (
            <Button
              appearance="secondary"
              onClick={() => history.push('/admin')}
            >
              Admin
            </Button>
          )}
          {oidcEnabled && (
            <Button
              appearance="secondary"
              onClick={() => window.location.replace('/api/logout')}
            >
              Logout
            </Button>
          )}
        </HeaderContent>
      </HeaderContainer>
    </Root>
  );
}

// STYLES

const Root = styled('header')`
  background: ${(p) => p.theme.header.background};
`;

const HeaderContainer = styled(Container)`
  align-items: center;
  display: flex;
  height: 70px;
  margin: auto;
  padding: 0 20px;
`;

const HeaderLogo = styled('div')`
  flex: 0 0 350px;
  width: 350px;

  svg {
    flex: 0 0 185px;
    margin-right: 16px;
    width: 185px;
  }

  a:link,
  a:visited {
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration: none;
  }
`;

const HeaderContent = styled('div')`
  margin-left: auto;

  button {
    + button {
      margin-left: ${unit()}px;
    }
  }
`;
