import styled, { css } from 'styled-components';
import {
  BoxSideValue,
  BoxValue,
  parseBoxSideValue,
  parseBoxValue,
  unit,
} from '../styles';

type TextWeight = 'normal' | 'medium' | 'bold';

interface Props {
  color?: string;
  lineHeight?: number;
  monospace?: boolean;
  size?: number;
  truncateOverflow?: boolean;
  weight?: TextWeight;
  whiteSpace?: 'normal' | 'nowrap' | 'inherit';
  wordBreak?: 'normal' | 'break-all' | 'break-word';
}

export const Text = styled.span<Props>`
  color: ${(p) => p.color ?? p.theme.app.color};
  font-weight: ${(p) => WeightMap[p.weight ?? 'normal']};
  font-size: ${(p) => (p.size ? `${p.size}px` : 'inherit')};
  white-space: ${(p) => p.whiteSpace ?? 'normal'};
  word-break: ${(p) => p.wordBreak ?? 'normal'};

  ${(p) =>
    p.monospace &&
    css`
      font-family: monospace;
    `};

  ${(p) =>
    p.lineHeight &&
    css`
      line-height: ${p.lineHeight};
    `};

  ${(p) =>
    p.truncateOverflow &&
    css`
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;

interface ParagraphProps extends Props {
  margin?: BoxValue;
  marginLeft?: BoxSideValue;
  marginTop?: BoxSideValue;
}

export const Paragraph = styled.p<ParagraphProps>`
  color: ${(p) => p.color ?? p.theme.app.color};
  font-weight: ${(p) => WeightMap[p.weight ?? 'normal']};
  font-size: ${(p) => (p.size ? `${p.size}px` : 'inherit')};
  margin: 0;
  white-space: ${(p) => p.whiteSpace ?? 'normal'};
  word-break: ${(p) => p.wordBreak ?? 'normal'};

  & + & {
    margin-top: ${unit(2)}px;
  }

  ${(p) =>
    p.lineHeight &&
    css`
      line-height: ${p.lineHeight};
    `};

  ${(p) =>
    p.margin &&
    css`
      margin: ${parseBoxValue(p.margin)};
    `};

  ${(p) =>
    p.marginLeft &&
    css`
      margin-left: ${parseBoxSideValue(p.marginLeft)};
    `};

  ${(p) =>
    p.marginTop &&
    css`
      margin-top: ${parseBoxSideValue(p.marginTop)};
    `};

  ${(p) =>
    p.monospace &&
    css`
      font-family: monospace;
    `};

  ${(p) =>
    p.truncateOverflow &&
    css`
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `};
`;

const WeightMap: Record<TextWeight, number> = {
  bold: 700,
  medium: 500,
  normal: 400,
};

export const ValueLabel = styled(Paragraph).attrs((p) => ({
  color: p.theme.label.color,
  size: p.theme.label.fontSize,
}))`
  margin: 0 0 ${unit()}px;
  opacity: 0.5;
`;
