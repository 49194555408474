import '@/shared/i18n';
import { OverlayProvider } from '@react-aria/overlays';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { lazy } from '@/shared/utils/retryable-lazy';
import { useAppDataQuery } from './__generated__/app.query';
import { AppContextProvider, useAppContext } from './app-context';
import { AppErrorMessage } from './app-error';
import { AuthContext, CurrentUser } from './auth-context';
import { Header } from './header';
import { AdminRoute } from './shared/components/conditional-route';
import { SkillsThemeProvider } from './shared/components/theme-provider';
import { ApiProvider } from './shared/services/api-provider';
import { isApiAuthError } from './shared/utils/api-error-codes';

const Login = lazy(
  () => import(/* webpackChunkName: "admin" */ './modules/account/login'),
  'Login',
);

const Admin = lazy(
  () => import(/* webpackChunkName: "admin" */ './modules/admin'),
  'Admin',
);

const Dashboard = lazy(
  () => import(/* webpackChunkName: "dashboard" */ './modules/dashboard'),
  'Dashboard',
);

const Labs = lazy(
  () => import(/* webpackChunkName: "dashboard" */ './modules/labs'),
  'Labs',
);

export function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <ApiProvider>
          <SkillsThemeProvider>
            <React.Suspense fallback={null}>
              <GlobalStyles />
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/error">
                  <AppErrorMessage />
                </Route>
                <Route path="/">
                  <AppRoot />
                </Route>
              </Switch>

              <div id="cyber-range-overlays" />
            </React.Suspense>
          </SkillsThemeProvider>
        </ApiProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export function AppRoot() {
  const { embedded } = useAppContext();
  const { data, error, loading } = useAppDataQuery();

  const contextValue = React.useMemo(() => {
    if (!data?.me) return;

    return {
      currentUser: new CurrentUser({
        email: data.me.email,
        id: data.me.id,
        firstName: data.me.firstName,
        lastName: data.me.lastName,
        roles: data.me.roles,
      }),
      sourceApp: data.me.sourceAppSettings,
      oidcEnabled: data.appInfo.oidcEnabled,
    };
  }, [data?.me, data?.appInfo]);

  if (loading) return null;

  let content;

  if (isApiAuthError(error?.graphQLErrors)) {
    return null;
  }

  if (error || !data) {
    content = <AppErrorMessage>{error?.message}</AppErrorMessage>;
  } else {
    content = (
      <Switch>
        <AdminRoute path="/admin">
          <Admin />
        </AdminRoute>
        <Route path="/labs">
          <Labs />
        </Route>
        <Route path="/" exact={true}>
          <Dashboard />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  if (!embedded) {
    content = (
      <Root>
        <Header sourceApp={contextValue?.sourceApp} />
        <AppContent>{content}</AppContent>
      </Root>
    );
  }

  return (
    <OverlayProvider>
      <AuthContext.Provider value={contextValue}>
        {content}
      </AuthContext.Provider>
    </OverlayProvider>
  );
}

// STYLES

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${(p) => p.theme.app.background};
    box-sizing: border-box;
    color: ${(p) => p.theme.app.color};
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  input, button {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  }

  a:link, a:visited {
    color: #fff;
  }
`;

const Root = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const AppContent = styled.div`
  flex: 1;
  position: relative;
`;
