import * as React from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { useAppContext } from '@/app-context';
import { COLORS } from '../styles';

type Props = Omit<React.ComponentProps<typeof ThemeProvider>, 'theme'>;

export function SkillsThemeProvider(props: Props) {
  const { embedded } = useAppContext();

  return (
    <ThemeProvider
      theme={embedded ? EMBEDDED_DARK_THEME : DARK_THEME}
      {...props}
    />
  );
}

const DARK_THEME: DefaultTheme = {
  app: {
    background: COLORS.Grey900,
    borderColor: COLORS.Grey500,
    color: COLORS.White,
    highlight: COLORS.BrandBlue,
  },

  badge: {
    greenBackground: 'rgba(87, 171, 90, 0.1)',
    greenBorder: 'rgba(87,171,90,0.4);',
    greenColor: 'rgb(87, 171, 90)',

    greyBackground: 'transparent',
    greyBorder: '#424243',
    greyColor: COLORS.Grey100,

    redBackground: 'transparent',
    redBorder: 'rgb(191, 38, 0)',
    redColor: 'rgb(191, 38, 0)',

    yellowBackground: 'transparent',
    yellowBorder: '#f3cb45',
    yellowColor: '#f3cb45',
  },

  button: {
    danger: {
      background: COLORS.Red,
      border: COLORS.Red,
      color: COLORS.White,
      hoverBackground: COLORS.Red,
      hoverBorder: COLORS.Red,
    },
    primary: {
      background: COLORS.BrandBlue,
      border: COLORS.BrandBlue,
      color: COLORS.White,
      hoverBackground: COLORS.Sky2,
      hoverBorder: COLORS.Sky2,
    },
    secondary: {
      background: 'transparent',
      border: COLORS.BrandBlue,
      color: COLORS.White,
      hoverBorder: COLORS.Sky2,
    },
    subtle: {
      background: 'transparent',
      border: '#838383',
      color: '#696969',
      hoverBorder: '#5c5c5c',
      hoverColor: '#434343',
    },
  },

  card: {
    background: 'transparent',
    backgroundFilled: COLORS.Grey700,
    borderRadiusSize: 2,
    paddingSize: 2,
  },

  header: {
    background: COLORS.Grey900,
  },

  lab: {
    framePaddingSide: 2,
    framePaddingTop: 2,
  },

  label: {
    color: '#fff',
    errorColor: COLORS.Red,
    fontSize: 12,
  },
};

const EMBEDDED_DARK_THEME: DefaultTheme = {
  ...DARK_THEME,
  lab: {
    ...DARK_THEME.lab,
    framePaddingSide: 0,
    framePaddingTop: 0,
  },
};
