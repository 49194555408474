import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError as apolloOnError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { GraphQLError } from 'graphql';
import { createClient as createWsClient } from 'graphql-ws';
import * as React from 'react';
import { RefreshTokenDocument } from '@/__generated__/app.query';
import { useAppContext } from '@/app-context';
import { isApiAuthError, isApiForbiddenError } from '../utils/api-error-codes';

export const defaultClient = createClient();

export const ApiProvider: React.FC<{ uri?: string }> = ({ children, uri }) => {
  const { embedded } = useAppContext();

  const clientRef = React.useRef(
    createClient({
      onError: createErrorRedirector(embedded),
      uri,
    }),
  );

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
};

const getNewToken = async (): Promise<string | undefined> => {
  const result = await defaultClient.query({
    fetchPolicy: 'no-cache',
    query: RefreshTokenDocument,
  });

  return result?.data?.me.subscriptionToken;
};

interface CreateClientOptions {
  onError(errors?: ReadonlyArray<GraphQLError>): void;
  uri?: string;
  wsUri?: string;
}

export function createClient({
  onError = createErrorRedirector(),
  uri = '/api/graphql',
  wsUri = getDefaultWebSocketUrl(),
}: Partial<CreateClientOptions> = {}) {
  const httpLink = new HttpLink({
    uri,
    credentials: 'same-origin',
  });

  // createWsClient has reasonable defaults
  // for retrying connections should one fail.
  const wsLink = new GraphQLWsLink(
    createWsClient({
      url: wsUri,
      lazy: true,
      connectionParams: async () => ({
        subscriptionToken: await getNewToken(),
      }),
    }),
  );

  const splitLink = split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return (
        def.kind === 'OperationDefinition' && def.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const cache = new InMemoryCache({
    typePolicies: {
      Lab: {
        fields: {
          machines: { merge: (_e, incoming) => incoming },
          steps: { merge: (_e, incoming) => incoming },
        },
      },
      LabStep: {
        fields: {
          inspections: {
            merge: (_e, incoming) => incoming,
          },
        },
      },
      Query: {
        fields: {
          auditConnection: relayStylePagination(),
        },
      },
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([
      apolloOnError(({ graphQLErrors, networkError }) => {
        if (
          networkError?.message === 'A valid subscriptionToken is required.' ||
          isApiAuthError(graphQLErrors) ||
          isApiForbiddenError(graphQLErrors)
        ) {
          onError(graphQLErrors);
        }
      }),
      splitLink,
    ]),
    cache,
  });
}

function createErrorRedirector(embedded?: boolean) {
  return (errors: ReadonlyArray<GraphQLError>) => {
    if (embedded || !isApiAuthError(errors)) {
      const url = new URL('/error', window.location.origin);
      // NOTE(bng): Preserve query string for hideChrome
      url.search = window.location.search;
      window.location.assign(url.toString());
      return;
    }

    const loginUrl =
      (errors.find((e) => !!e?.extensions?.['loginUrl'])?.extensions?.[
        'loginUrl'
      ] as string) ?? '/login';
    const url = new URL(loginUrl, window.location.origin);

    if (window.location.pathname !== '/') {
      url.searchParams.set(
        'redirectUrl',
        window.location.pathname + window.location.search,
      );
    }

    window.location.assign(url.toString());
  };
}

function getDefaultWebSocketUrl() {
  return `ws${window.location.protocol === 'https:' ? 's' : ''}://${
    window.location.host
  }/api/gql-sub`;
}
