import * as React from 'react';
import styled, { css } from 'styled-components';
import { borderRadius, unit } from '../styles';

type ButtonAppearance = 'primary' | 'secondary' | 'subtle' | 'link' | 'danger';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  appearance?: ButtonAppearance;
  minWidth?: number;
};

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ({ appearance = 'secondary', ...props }, ref) => {
    return <Root appearance={appearance} ref={ref} type="button" {...props} />;
  },
);

const Root = styled.button<{ appearance: ButtonAppearance; minWidth?: number }>`
  border: 2px solid;
  border-radius: ${borderRadius()}px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-height: 42px;
  outline: 0;
  padding: 0 ${unit()}px;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-in-out, transform 0.1s ease-in-out;

  ${(p) =>
    p.minWidth &&
    css`
      min-width: ${p.minWidth}px;
    `};

  &:focus {
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.35);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(p) =>
    (!p.appearance || p.appearance === 'primary') &&
    css`
      background-color: ${(p) => p.theme.button.primary.background};
      border-color: ${(p) => p.theme.button.primary.border};
      color: ${(p) => p.theme.button.primary.color};

      &:not(:disabled):hover {
        background-color: ${(p) => p.theme.button.primary.hoverBackground};
        border-color: ${(p) => p.theme.button.primary.hoverBorder};
      }
    `};

  ${(p) =>
    p.appearance === 'secondary' &&
    css`
      background-color: ${(p) => p.theme.button.secondary.background};
      border-color: ${(p) => p.theme.button.secondary.border};
      color: ${(p) => p.theme.button.secondary.color};

      &:not(:disabled):hover {
        border-color: ${(p) => p.theme.button.secondary.hoverBorder};
      }
    `};

  ${(p) =>
    p.appearance === 'subtle' &&
    css`
      background-color: ${(p) => p.theme.button.subtle.background};
      border-color: ${(p) => p.theme.button.subtle.border};
      color: ${(p) => p.theme.button.subtle.color};

      &:not(:disabled):hover {
        border-color: #5c5c5c;
        color: #434343;
      }
    `};

  ${(p) =>
    p.appearance === 'danger' &&
    css`
      background-color: ${(p) => p.theme.button.danger.background};
      border-color: ${(p) => p.theme.button.danger.border};
      color: ${(p) => p.theme.button.danger.color};

      &:not(:disabled):hover {
        border-color: ${(p) => p.theme.button.danger.hoverBorder};
      }
    `};

  ${(p) =>
    p.appearance === 'link' &&
    css`
      background-color: transparent;
      border: 0;
      color: inherit;
      font-family: inherit;
      font-weight: inherit;
    `};
`;
