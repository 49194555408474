import { GraphQLErrors } from '@apollo/client/errors';

export enum ApiErrorCodes {
  Forbidden = 'FORBIDDEN',
  Unauthenticated = 'UNAUTHENTICATED',
}

const hasErrorCode = (code: ApiErrorCodes, errors?: GraphQLErrors) => {
  return errors?.some((e) => e.extensions?.code === code) ?? false;
};

export const isApiAuthError = (errors?: GraphQLErrors) => {
  return hasErrorCode(ApiErrorCodes.Unauthenticated, errors);
};

export const isApiForbiddenError = (errors?: GraphQLErrors) => {
  return hasErrorCode(ApiErrorCodes.Forbidden, errors);
};
