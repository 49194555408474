import styled, { css } from 'styled-components';
import {
  borderRadius,
  BoxSideValue,
  BoxValue,
  parseBoxSideValue,
  parseBoxValue,
  unit,
} from '../styles';

export interface BoxProps {
  alignItems?: string;
  background?: string;
  borderRadius?: number | [number, number];
  display?: 'block' | 'inline-block' | 'inline-flex' | 'flex' | 'none';
  flex?: number | string;
  flexDirection?: 'column' | 'row';
  height?: number | 'auto';
  justifyContent?: 'center' | 'flex-end' | 'flex-start';
  margin?: BoxValue;
  marginLeft?: BoxSideValue;
  marginRight?: BoxSideValue;
  marginTop?: BoxSideValue;
  maxWidth?: number | string;
  minWidth?: number | string;
  overflow?: 'auto' | 'hidden';
  padding?: BoxValue;
  position?: 'relative' | 'absolute';
  textAlign?: 'center';
}

const SKIP_FORWARD_PROPS = new Set(['display', 'overflow']);

export const Box = styled.div.withConfig({
  shouldForwardProp: (p, defaultFn) => {
    if (SKIP_FORWARD_PROPS.has(p)) {
      return false;
    }

    return defaultFn(p);
  },
})<BoxProps>`
  ${(p) =>
    p.alignItems &&
    css`
      align-items: ${p.alignItems};
    `};

  ${(p) =>
    p.background &&
    css`
      background: ${p.background};
    `};

  ${(p) =>
    p.borderRadius &&
    css`
      border-radius: ${Array.isArray(p.borderRadius)
        ? `${borderRadius(p.borderRadius[0])}px ${borderRadius(
            p.borderRadius[0],
          )}px ${borderRadius(p.borderRadius[1])}px ${borderRadius(
            p.borderRadius[1],
          )}px`
        : `${borderRadius(p.borderRadius)}px`};
    `};

  ${(p) =>
    p.display &&
    css`
      display: ${p.display};
    `};

  ${(p) =>
    p.flex &&
    css`
      flex: ${p.flex};
    `};

  ${(p) =>
    p.flexDirection &&
    css`
      flex-direction: ${p.flexDirection};
    `};

  ${(p) =>
    p.height &&
    css`
      height: ${p.height === 'auto' ? 'auto' : `${p.height}px`};
    `};

  ${(p) =>
    p.justifyContent &&
    css`
      justify-content: ${p.justifyContent};
    `};

  ${(p) =>
    p.margin &&
    css`
      margin: ${parseBoxValue(p.margin)};
    `};

  ${(p) =>
    p.marginLeft &&
    css`
      margin-left: ${parseBoxSideValue(p.marginLeft)};
    `};

  ${(p) =>
    p.marginRight &&
    css`
      margin-right: ${parseBoxSideValue(p.marginRight)};
    `};

  ${(p) =>
    p.marginTop &&
    css`
      margin-top: ${parseBoxSideValue(p.marginTop)};
    `};

  ${(p) =>
    p.maxWidth &&
    css`
      max-width: ${typeof p.maxWidth === 'number'
        ? `${p.maxWidth}px`
        : p.maxWidth};
    `};

  ${(p) =>
    p.minWidth &&
    css`
      min-width: ${typeof p.minWidth === 'number'
        ? `${p.minWidth}px`
        : p.minWidth};
    `};

  ${(p) =>
    p.overflow &&
    css`
      overflow: ${p.overflow};
    `};

  ${(p) =>
    p.padding &&
    css`
      padding: ${parseBoxValue(p.padding)};
    `};

  ${(p) =>
    p.position &&
    css`
      position: ${p.position};
    `};

  ${(p) =>
    p.textAlign &&
    css`
      text-align: ${p.textAlign};
    `};
`;

export const Stack = styled(Box)`
  > * {
    &:not(:first-child) {
      margin-top: ${unit(2)}px;
    }
  }
`;

export const Columns = styled(Box)<{ spaceBetween?: number }>`
  display: flex;

  ${(p) =>
    p.spaceBetween &&
    css`
      > * {
        &:not(:first-child) {
          margin-left: ${unit(p.spaceBetween)}px;
        }
      }
    `};
`;

Columns.defaultProps = { spaceBetween: 2 };
