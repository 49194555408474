import * as React from 'react';
import { UserRole } from './__generated__/types';

interface ApiCurrentUser {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  roles: Array<UserRole>;
}

export interface ICurrentUser {
  email: string;
  id: string;
  firstName: string;
  lastName: string;

  isAdmin(): boolean;
  isSuperAdmin(): boolean;
}

export class CurrentUser implements ICurrentUser {
  public email: string;
  public id: string;
  public firstName: string;
  public lastName: string;

  private _roles: Set<UserRole>;

  constructor(data: ApiCurrentUser) {
    this.email = data.email;
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;

    this._roles = new Set(data.roles ?? []);
  }

  isAdmin() {
    return this._roles.has(UserRole.Admin);
  }

  isSuperAdmin() {
    return this._roles.has(UserRole.Superadmin);
  }
}

export interface IAuthContext {
  currentUser: CurrentUser;
  sourceApp: {
    name: string;
    returnUrl: string;
  };
  oidcEnabled: boolean;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(
  undefined,
);

export function useAuthContext() {
  const context = React.useContext(AuthContext);

  if (typeof context === 'undefined') {
    throw new Error(
      'useAuthContext must be used within an AuthContext provider',
    );
  }

  return context;
}
