import * as React from 'react';
import { Route } from 'react-router-dom';
import { ConditionalRoute } from '@infoseci/routing';
import { useAuthContext } from '@/auth-context';
import { canAccessAdmin } from '../utils/auth';

export function AdminRoute(props: React.ComponentProps<typeof Route>) {
  const { currentUser } = useAuthContext();

  return <ConditionalRoute if={() => canAccessAdmin(currentUser)} {...props} />;
}
