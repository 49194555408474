export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AgentReadyInfo = {
  __typename?: 'AgentReadyInfo';
  message: Scalars['String']['output'];
};

export type AgentStartInfo = {
  __typename?: 'AgentStartInfo';
  message: Scalars['String']['output'];
  subscriptionToken: Scalars['String']['output'];
};

export type AppInfo = {
  __typename?: 'AppInfo';
  maintenanceMode: Scalars['Boolean']['output'];
  oidcEnabled: Scalars['Boolean']['output'];
  versionCommit: Scalars['String']['output'];
  versionDate: Scalars['String']['output'];
};

export type AppUser = {
  __typename?: 'AppUser';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  roles: Array<UserRole>;
  sourceAppSettings: SourceAppSettings;
  subscriptionToken: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type Audit = Node & {
  __typename?: 'Audit';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entityType: AuditEntityType;
  eventData: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  ipAddress: Scalars['String']['output'];
  message: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AuditConnection = {
  __typename?: 'AuditConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<AuditEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AuditDetailLogEntry = {
  __typename?: 'AuditDetailLogEntry';
  message: Scalars['String']['output'];
  streamId: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type AuditEdge = {
  __typename?: 'AuditEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Audit;
};

export enum AuditEntityType {
  ContentFile = 'CONTENT_FILE',
  Lab = 'LAB',
  LabInstance = 'LAB_INSTANCE',
  LabInstanceMachine = 'LAB_INSTANCE_MACHINE',
  LabInstanceSession = 'LAB_INSTANCE_SESSION',
  LabInstanceStep = 'LAB_INSTANCE_STEP',
  LabMachine = 'LAB_MACHINE',
  LabStep = 'LAB_STEP',
  MachineImage = 'MACHINE_IMAGE',
  User = 'USER'
}

export type AuditFilterInput = {
  where: AuditWhereInput;
};

export type AuditOrderByInput = {
  createdAt: OrderByArg;
};

export type AuditWhereInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  transactionId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ConnectionStatus {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED'
}

export enum ConnectionType {
  Rdp = 'RDP',
  Ssh = 'SSH',
  Vnc = 'VNC'
}

export type ContentFile = Node & {
  __typename?: 'ContentFile';
  downloadUrl: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  uploadUrl: Scalars['String']['output'];
};

export type EventSubscription = {
  __typename?: 'EventSubscription';
  eventData: Scalars['JSON']['output'];
  eventType: PubSubEventType;
};

export type Inspection = {
  __typename?: 'Inspection';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inspectionData: Scalars['JSON']['output'];
  labMachineId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  type: InspectionType;
};

export type InspectionCreateInput = {
  description: Scalars['String']['input'];
  inspectionData: Scalars['JSON']['input'];
  labId: Scalars['ID']['input'];
  labStepId: Scalars['ID']['input'];
  machineId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  type: InspectionType;
};

export type InspectionDeleteInput = {
  id: Scalars['ID']['input'];
  labId: Scalars['ID']['input'];
};

export type InspectionInstance = {
  __typename?: 'InspectionInstance';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inspectionData: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  stepOrder: Scalars['Int']['output'];
  type: InspectionType;
};

export enum InspectionType {
  Command = 'COMMAND',
  FileByteContent = 'FILE_BYTE_CONTENT',
  FileExist = 'FILE_EXIST',
  FileTextContent = 'FILE_TEXT_CONTENT',
  Process = 'PROCESS',
  TerminalOutput = 'TERMINAL_OUTPUT',
  UserConfirm = 'USER_CONFIRM'
}

export type InspectionUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inspectionData?: InputMaybe<Scalars['JSON']['input']>;
  labId: Scalars['ID']['input'];
  machineId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<InspectionType>;
};

export type Lab = Node & {
  __typename?: 'Lab';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  exportDownloadPath?: Maybe<Scalars['String']['output']>;
  exportStatus: LabExportStatus;
  id: Scalars['ID']['output'];
  instance?: Maybe<LabInstance>;
  instances: Array<LabInstance>;
  isPublished: Scalars['Boolean']['output'];
  machines: Array<LabMachine>;
  name: Scalars['String']['output'];
  step?: Maybe<LabStep>;
  steps: Array<LabStep>;
  timeLimit: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: User;
  updatedById: Scalars['String']['output'];
  warmPoolCurrentSize?: Maybe<Scalars['Int']['output']>;
  warmPoolDisableIdleScaleIn: Scalars['Boolean']['output'];
  warmPoolScaledAt?: Maybe<Scalars['DateTime']['output']>;
  warmPoolSize: Scalars['Int']['output'];
};


export type LabStepArgs = {
  id: Scalars['String']['input'];
};

export type LabCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  warmPoolSize?: InputMaybe<Scalars['Int']['input']>;
};

export type LabDeleteInput = {
  id: Scalars['ID']['input'];
};

export type LabExportInput = {
  id: Scalars['ID']['input'];
};

export enum LabExportStatus {
  Available = 'AVAILABLE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Unavailable = 'UNAVAILABLE'
}

export type LabFileCreateInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  labId: Scalars['String']['input'];
};

export type LabImportFile = {
  __typename?: 'LabImportFile';
  filePath: Scalars['String']['output'];
  uploadPath: Scalars['String']['output'];
};

export type LabImportInput = {
  filePath: Scalars['String']['input'];
};

export type LabInstance = Node & {
  __typename?: 'LabInstance';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  currentStep?: Maybe<LabInstanceStep>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  machines: Array<LabInstanceMachine>;
  name: Scalars['String']['output'];
  session?: Maybe<LabInstanceSession>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: LabInstanceStatus;
  step?: Maybe<LabInstanceStep>;
  steps: Array<LabInstanceStep>;
  userId: Scalars['String']['output'];
};


export type LabInstanceSessionArgs = {
  id: Scalars['String']['input'];
};


export type LabInstanceStepArgs = {
  id: Scalars['String']['input'];
};

export type LabInstanceBroadcastInput = {
  instanceId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
};

export type LabInstanceCreateInput = {
  debugEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  labContext?: InputMaybe<Scalars['String']['input']>;
  labId: Scalars['ID']['input'];
};

export type LabInstanceInspectionCompleteInput = {
  inspectionInstanceId: Scalars['ID']['input'];
  labInstanceId: Scalars['ID']['input'];
};

export type LabInstanceMachine = Node & {
  __typename?: 'LabInstanceMachine';
  id: Scalars['ID']['output'];
  inspections: Array<InspectionInstance>;
  labMachineId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  platform: MachinePlatform;
  sessions: Array<LabInstanceSession>;
  status: LabInstanceMachineStatus;
  virtualizationType: VirtualizationType;
};

export type LabInstanceMachineAgentLogInput = {
  labInstanceId: Scalars['ID']['input'];
  labInstanceMachineId: Scalars['ID']['input'];
  logLevel: Scalars['String']['input'];
  logMessage: Scalars['String']['input'];
};

export type LabInstanceMachineAgentReadyInput = {
  labInstanceMachineId: Scalars['ID']['input'];
};

export type LabInstanceMachineAgentStartInput = {
  labInstanceMachineId: Scalars['ID']['input'];
  signature: Scalars['String']['input'];
};

export type LabInstanceMachineInspectionInput = {
  data: Scalars['String']['input'];
  inspectionInstanceId: Scalars['ID']['input'];
  labInstanceId: Scalars['ID']['input'];
  labInstanceMachineId: Scalars['ID']['input'];
};

export type LabInstanceMachineLoadInspectionsInput = {
  labInstanceId: Scalars['ID']['input'];
  labInstanceMachineId: Scalars['ID']['input'];
};

export enum LabInstanceMachineStatus {
  Deleting = 'DELETING',
  Error = 'ERROR',
  Pending = 'PENDING',
  Ready = 'READY'
}

export type LabInstanceSession = Node & {
  __typename?: 'LabInstanceSession';
  connectionStatus: ConnectionStatus;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  labInstanceMachine: LabInstanceMachine;
  labInstanceMachineId: Scalars['String']['output'];
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type LabInstanceSessionStartInput = {
  labInstanceMachineId: Scalars['ID']['input'];
};

export enum LabInstanceStatus {
  Completed = 'COMPLETED',
  Deleting = 'DELETING',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Ready = 'READY'
}

export type LabInstanceStep = Node & {
  __typename?: 'LabInstanceStep';
  content: Scalars['String']['output'];
  contentFileIds: Array<Scalars['String']['output']>;
  contentFiles: Array<ContentFile>;
  hints: Array<StepHint>;
  id: Scalars['ID']['output'];
  inspections: Array<InspectionInstance>;
  labStepId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  status: LabInstanceStepStatus;
  userConfirmInspectionId?: Maybe<Scalars['String']['output']>;
  videos: Array<StepVideo>;
};

export enum LabInstanceStepStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE'
}

export type LabMachine = Node & {
  __typename?: 'LabMachine';
  connectionPassword?: Maybe<Scalars['String']['output']>;
  connectionPort: Scalars['Int']['output'];
  connectionType: ConnectionType;
  connectionUser?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hostname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageName: Scalars['String']['output'];
  isPrivileged: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  platform: MachinePlatform;
  role: MachineRole;
  virtualizationType: VirtualizationType;
};

export type LabMachineCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hostname: Scalars['String']['input'];
  isPrivileged?: InputMaybe<Scalars['Boolean']['input']>;
  labId: Scalars['ID']['input'];
  machineImageId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  role?: InputMaybe<MachineRole>;
};

export type LabMachineDeleteInput = {
  id: Scalars['ID']['input'];
  labId: Scalars['ID']['input'];
};

export type LabMachineUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isPrivileged?: InputMaybe<Scalars['Boolean']['input']>;
  labId: Scalars['ID']['input'];
  machineImageId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<MachineRole>;
};

export type LabMachinesReorderInput = {
  labId: Scalars['ID']['input'];
  machineIds: Array<Scalars['String']['input']>;
};

export type LabSearchInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LabStep = Node & {
  __typename?: 'LabStep';
  content: Scalars['String']['output'];
  contentFileIds: Array<Scalars['String']['output']>;
  contentFiles: Array<ContentFile>;
  hints: Array<StepHint>;
  id: Scalars['ID']['output'];
  inspections: Array<Inspection>;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  videos: Array<StepVideo>;
};

export type LabStepCreateInput = {
  content: Scalars['String']['input'];
  contentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  labId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type LabStepDeleteInput = {
  id: Scalars['ID']['input'];
  labId: Scalars['ID']['input'];
};

export type LabStepUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  labId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type LabStepsReorderInput = {
  labId: Scalars['ID']['input'];
  stepIds: Array<Scalars['String']['input']>;
};

export type LabUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timeLimit?: InputMaybe<Scalars['Int']['input']>;
  warmPoolDisableIdleScaleIn?: InputMaybe<Scalars['Boolean']['input']>;
  warmPoolSize?: InputMaybe<Scalars['Int']['input']>;
};

export type LoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MachineImage = {
  __typename?: 'MachineImage';
  connectionType: ConnectionType;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageName?: Maybe<Scalars['String']['output']>;
  labs: Array<Lab>;
  name: Scalars['String']['output'];
  platform: MachinePlatform;
  slug: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  sourceCredentials?: Maybe<Scalars['String']['output']>;
  sourceType: MachineImageSourceType;
  status: MachineImageStatus;
  virtualizationType: VirtualizationType;
};

export type MachineImageCreateInput = {
  connectionPassword?: InputMaybe<Scalars['String']['input']>;
  connectionPort: Scalars['Int']['input'];
  connectionType: ConnectionType;
  connectionUser?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  platform: MachinePlatform;
  slug: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  sourceCredentials?: InputMaybe<Scalars['String']['input']>;
  sourceType: MachineImageSourceType;
  virtualizationType: VirtualizationType;
};

export type MachineImageDeleteInput = {
  id: Scalars['ID']['input'];
};

export type MachineImageFileCreateInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  imageId: Scalars['String']['input'];
};

export type MachineImageRebuildInput = {
  imageIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MachineImageSearchInput = {
  connectionTypes?: InputMaybe<Array<ConnectionType>>;
  virtualizationTypes?: InputMaybe<Array<VirtualizationType>>;
};

export enum MachineImageSourceType {
  Ami = 'AMI',
  Docker = 'DOCKER',
  Dockerfile = 'DOCKERFILE',
  Ova = 'OVA'
}

export enum MachineImageStatus {
  Available = 'AVAILABLE',
  Building = 'BUILDING',
  Deleting = 'DELETING',
  Error = 'ERROR',
  Pending = 'PENDING'
}

export type MachineImageUpdateInput = {
  connectionPassword?: InputMaybe<Scalars['String']['input']>;
  connectionPort?: InputMaybe<Scalars['Int']['input']>;
  connectionType?: InputMaybe<ConnectionType>;
  connectionUser?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<MachinePlatform>;
  slug?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  sourceCredentials?: InputMaybe<Scalars['String']['input']>;
  sourceType?: InputMaybe<MachineImageSourceType>;
  status?: InputMaybe<MachineImageStatus>;
  virtualizationType?: InputMaybe<VirtualizationType>;
};

export enum MachinePlatform {
  LinuxMuslX64 = 'LINUX_MUSL_X64',
  LinuxX64 = 'LINUX_X64',
  WindowsX64 = 'WINDOWS_X64'
}

export enum MachineRole {
  Cpu = 'CPU',
  User = 'USER'
}

export type Mutation = {
  __typename?: 'Mutation';
  inspectionCreate: Inspection;
  inspectionDelete: StatusMessage;
  inspectionUpdate: Inspection;
  labCreate: Lab;
  labDelete: StatusMessage;
  labExport: StatusMessage;
  labFileCreate: ContentFile;
  labImport: StatusMessage;
  labImportFileCreate: LabImportFile;
  labInstanceBroadcast: StatusMessage;
  labInstanceCreate: LabInstance;
  labInstanceDelete: StatusMessage;
  labInstanceMachineAgentLog: StatusMessage;
  labInstanceMachineAgentReady: AgentReadyInfo;
  labInstanceMachineAgentStart: AgentStartInfo;
  labInstanceMachineInspectionComplete: StatusMessage;
  labInstanceSessionStart: LabInstanceSession;
  labInstanceUserInspectionComplete: StatusMessage;
  labMachineCreate: LabMachine;
  labMachineDelete: StatusMessage;
  labMachineUpdate: LabMachine;
  labMachinesReorder: Array<LabMachine>;
  labStepCreate: LabStep;
  labStepDelete: StatusMessage;
  labStepUpdate: LabStep;
  labStepsReorder: Array<LabStep>;
  labUpdate: Lab;
  login: StatusMessage;
  machineImageCreate: MachineImage;
  machineImageDelete: StatusMessage;
  machineImageFileCreate: ContentFile;
  machineImageRebuild: StatusMessage;
  machineImageUpdate: MachineImage;
  setMaintenanceMode: StatusMessage;
  stepHintCreate: StepHint;
  stepHintDelete: StatusMessage;
  stepHintUpdate: StepHint;
  stepVideoCreate: StepVideo;
  stepVideoDelete: StatusMessage;
  stepVideoUpdate: StepVideo;
  userBootstrap: User;
  userCreate: User;
  userDelete: StatusMessage;
  userUpdate: User;
};


export type MutationInspectionCreateArgs = {
  input: InspectionCreateInput;
};


export type MutationInspectionDeleteArgs = {
  input: InspectionDeleteInput;
};


export type MutationInspectionUpdateArgs = {
  input: InspectionUpdateInput;
};


export type MutationLabCreateArgs = {
  input: LabCreateInput;
};


export type MutationLabDeleteArgs = {
  input: LabDeleteInput;
};


export type MutationLabExportArgs = {
  input: LabExportInput;
};


export type MutationLabFileCreateArgs = {
  input: LabFileCreateInput;
};


export type MutationLabImportArgs = {
  input: LabImportInput;
};


export type MutationLabInstanceBroadcastArgs = {
  input: LabInstanceBroadcastInput;
};


export type MutationLabInstanceCreateArgs = {
  input: LabInstanceCreateInput;
};


export type MutationLabInstanceDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationLabInstanceMachineAgentLogArgs = {
  input: LabInstanceMachineAgentLogInput;
};


export type MutationLabInstanceMachineAgentReadyArgs = {
  input: LabInstanceMachineAgentReadyInput;
};


export type MutationLabInstanceMachineAgentStartArgs = {
  input: LabInstanceMachineAgentStartInput;
};


export type MutationLabInstanceMachineInspectionCompleteArgs = {
  input: LabInstanceMachineInspectionInput;
};


export type MutationLabInstanceSessionStartArgs = {
  input: LabInstanceSessionStartInput;
};


export type MutationLabInstanceUserInspectionCompleteArgs = {
  input: LabInstanceInspectionCompleteInput;
};


export type MutationLabMachineCreateArgs = {
  input: LabMachineCreateInput;
};


export type MutationLabMachineDeleteArgs = {
  input: LabMachineDeleteInput;
};


export type MutationLabMachineUpdateArgs = {
  input: LabMachineUpdateInput;
};


export type MutationLabMachinesReorderArgs = {
  input: LabMachinesReorderInput;
};


export type MutationLabStepCreateArgs = {
  input: LabStepCreateInput;
};


export type MutationLabStepDeleteArgs = {
  input: LabStepDeleteInput;
};


export type MutationLabStepUpdateArgs = {
  input: LabStepUpdateInput;
};


export type MutationLabStepsReorderArgs = {
  input: LabStepsReorderInput;
};


export type MutationLabUpdateArgs = {
  input: LabUpdateInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationMachineImageCreateArgs = {
  input: MachineImageCreateInput;
};


export type MutationMachineImageDeleteArgs = {
  input: MachineImageDeleteInput;
};


export type MutationMachineImageFileCreateArgs = {
  input: MachineImageFileCreateInput;
};


export type MutationMachineImageRebuildArgs = {
  input: MachineImageRebuildInput;
};


export type MutationMachineImageUpdateArgs = {
  input: MachineImageUpdateInput;
};


export type MutationSetMaintenanceModeArgs = {
  input: SetMaintenanceModeInput;
};


export type MutationStepHintCreateArgs = {
  input: StepHintCreateInput;
};


export type MutationStepHintDeleteArgs = {
  input: StepHintDeleteInput;
};


export type MutationStepHintUpdateArgs = {
  input: StepHintUpdateInput;
};


export type MutationStepVideoCreateArgs = {
  input: StepVideoCreateInput;
};


export type MutationStepVideoDeleteArgs = {
  input: StepVideoDeleteInput;
};


export type MutationStepVideoUpdateArgs = {
  input: StepVideoUpdateInput;
};


export type MutationUserBootstrapArgs = {
  input: UserBootstrapInput;
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  input: UserDeleteInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

export type Node = {
  /** The object's unique id */
  id: Scalars['ID']['output'];
};

export enum OrderByArg {
  Asc = 'asc',
  Desc = 'desc'
}

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PubSubEventType {
  LabInstanceAgentLog = 'LAB_INSTANCE_AGENT_LOG',
  LabInstanceAgentNetworkReady = 'LAB_INSTANCE_AGENT_NETWORK_READY',
  LabInstanceBroadcast = 'LAB_INSTANCE_BROADCAST',
  LabInstanceComplete = 'LAB_INSTANCE_COMPLETE',
  LabInstanceError = 'LAB_INSTANCE_ERROR',
  LabInstanceInspectionComplete = 'LAB_INSTANCE_INSPECTION_COMPLETE',
  LabInstanceInspectionMatch = 'LAB_INSTANCE_INSPECTION_MATCH',
  LabInstanceStart = 'LAB_INSTANCE_START',
  LabInstanceStepComplete = 'LAB_INSTANCE_STEP_COMPLETE',
  LabInstanceStop = 'LAB_INSTANCE_STOP'
}

export type Query = {
  __typename?: 'Query';
  appInfo: AppInfo;
  audit: Audit;
  auditConnection: AuditConnection;
  auditDetailLog: Array<AuditDetailLogEntry>;
  audits: Array<Audit>;
  lab: Lab;
  labInstance: LabInstance;
  labInstanceMachine: LabInstanceMachine;
  labInstanceMachineLoadInspections: Array<InspectionInstance>;
  labInstanceMachines: Array<LabInstanceMachine>;
  labInstances: Array<LabInstance>;
  labMachine: LabMachine;
  labMachines: Array<LabMachine>;
  labs: Array<Lab>;
  machineImage: MachineImage;
  machineImageBuildLog: Array<AuditDetailLogEntry>;
  machineImages: Array<MachineImage>;
  me: AppUser;
  user: User;
  userConnection: UserConnection;
  users: Array<User>;
};


export type QueryAuditArgs = {
  id: Scalars['String']['input'];
};


export type QueryAuditConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AuditFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<AuditOrderByInput>;
};


export type QueryAuditDetailLogArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabInstanceArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabInstanceMachineArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabInstanceMachineLoadInspectionsArgs = {
  input: LabInstanceMachineLoadInspectionsInput;
};


export type QueryLabMachineArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabsArgs = {
  input?: InputMaybe<LabSearchInput>;
};


export type QueryMachineImageArgs = {
  id: Scalars['String']['input'];
};


export type QueryMachineImageBuildLogArgs = {
  id: Scalars['String']['input'];
};


export type QueryMachineImagesArgs = {
  input: MachineImageSearchInput;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SetMaintenanceModeInput = {
  enable: Scalars['Boolean']['input'];
};

export type SourceAppSettings = {
  __typename?: 'SourceAppSettings';
  name: Scalars['String']['output'];
  returnUrl: Scalars['String']['output'];
};

export type StatusMessage = {
  __typename?: 'StatusMessage';
  message: Scalars['String']['output'];
};

export type StepHint = Node & {
  __typename?: 'StepHint';
  content: Scalars['String']['output'];
  contentFileIds: Array<Scalars['String']['output']>;
  contentFiles: Array<ContentFile>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type StepHintCreateInput = {
  content: Scalars['String']['input'];
  contentFileIds: Array<Scalars['String']['input']>;
  labId: Scalars['ID']['input'];
  labStepId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type StepHintDeleteInput = {
  id: Scalars['String']['input'];
  labId: Scalars['ID']['input'];
};

export type StepHintUpdateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  labId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type StepVideo = Node & {
  __typename?: 'StepVideo';
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  videoFile: ContentFile;
};

export type StepVideoCreateInput = {
  fileId: Scalars['String']['input'];
  labId: Scalars['String']['input'];
  labStepId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type StepVideoDeleteInput = {
  id: Scalars['String']['input'];
  labId: Scalars['String']['input'];
};

export type StepVideoUpdateInput = {
  fileId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  labId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  labInstanceAgentSubscription: EventSubscription;
  labInstanceSubscription: EventSubscription;
};


export type SubscriptionLabInstanceAgentSubscriptionArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionLabInstanceSubscriptionArgs = {
  id: Scalars['String']['input'];
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
  source?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserBootstrapInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<UserEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserCreateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type UserDeleteInput = {
  id: Scalars['ID']['input'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: User;
};

export type UserFilterInput = {
  where: UserWhereInput;
};

export enum UserRole {
  Admin = 'ADMIN',
  Superadmin = 'SUPERADMIN',
  System = 'SYSTEM',
  User = 'USER'
}

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type UserWhereInput = {
  roles?: InputMaybe<Array<UserRole>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum VirtualizationType {
  Docker = 'DOCKER',
  Ec2 = 'EC2',
  Ecs = 'ECS',
  Manual = 'MANUAL',
  Virtualbox = 'VIRTUALBOX'
}
