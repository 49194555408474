import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container } from './shared/components/container';
import { unit } from './shared/styles';

export function AppErrorMessage({ children }: { children?: React.ReactNode }) {
  const { t } = useTranslation();

  return <Root>{children ?? t('APP.ERROR_GENERIC')}</Root>;
}
export const Root = styled(Container)`
  align-items: center;
  border: 2px solid #d8dadb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin: ${unit(3)}px auto;
  min-height: ${unit(20)}px;
`;
