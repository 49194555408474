export const borderRadius = (factor: number = 1) => factor * 4;
export const unit = (factor: number = 1) => factor * 10;

export const COLORS = {
  Green100: '#D3E7CD',
  Green300: '#A9D09A',
  Green500: '#53A136',
  Grey100: '#BBBCBC',
  Grey500: '#424243',
  Grey600: '#3D3F44',
  Grey700: '#2B2B2B',
  Grey800: '#1C1D1E',
  Grey900: '#161718',
  Purple500: '#E530AC',
  Red: '#C9252D',
  Sky2: '#009ace',
  White: '#fff',
  BrandBlue: '#007EA8',
};

export type BoxValue =
  | number
  | [number, number]
  | [number, number, number, number];

export type BoxSideValue = 'auto' | number;

export const parseBoxValue = (value: BoxValue) => {
  if (typeof value === 'number') {
    return `${unit(value)}px`;
  }

  return value.map((x) => `${unit(x)}px`).join(' ');
};

export const parseBoxSideValue = (value: BoxSideValue) => {
  return typeof value === 'number' ? `${unit(value)}px` : value;
};
