import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './locales';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
void i18n.use(initReactI18next).init({
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources,
});
