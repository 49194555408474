import * as React from 'react';
import { useLocation } from 'react-router';

export interface IAppContext {
  // TODO(bng): Support flipping dark/light modes
  dark?: boolean;
  embedded: boolean;
}

export const AppContext = React.createContext<IAppContext | undefined>(
  undefined,
);

export function useAppContext() {
  const context = React.useContext(AppContext);

  if (typeof context === 'undefined') {
    throw new Error('useAppContext must be used within a AppContext provider');
  }

  return context;
}

export function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const embedded =
    params.get('hideChrome') === '1' || params.get('hideChrome') === 'true';

  const value = React.useMemo(
    () => ({
      dark: true,
      embedded,
    }),
    [embedded],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
