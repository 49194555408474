import * as React from 'react';

export function LogoSkills(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 461.7 78"
      xmlSpace="preserve"
      {...props}
    >
      <g style={{ fill: '#fff' }}>
        <path d="M21,62.1H10V15h11V62.1z" />
        <path
          d="M71.3,15v47.1h-5.7c-0.8,0-1.5-0.1-2.1-0.4s-1.1-0.7-1.7-1.4L39.5,32.2c0.1,0.8,0.1,1.7,0.2,2.4v2.2v25.3h-9.6
	V15h5.8c0.5,0,0.9,0,1.2,0.1c0.3,0,0.6,0.1,0.9,0.2s0.5,0.3,0.7,0.5s0.5,0.5,0.8,0.9L61.9,45c-0.1-0.9-0.2-1.8-0.2-2.6
	c0-0.9-0.1-1.7-0.1-2.4V15C61.6,15,71.3,15,71.3,15z"
        />
        <path
          d="M105,38.5c0-1.1,0.1-2.1,0.1-3.2H91.4V23.4h17.1c1.5-3.1,3.5-5.9,5.9-8.3c0,0,0.1-0.1,0.2-0.1H80.4v47.1h11
	V43.8h14C105.2,42,105,40.3,105,38.5z"
        />
        <path
          d="M161.9,38.5c0,3.4-0.6,6.6-1.8,9.5s-2.8,5.5-5,7.7c-2.1,2.2-4.7,3.9-7.7,5.1s-6.3,1.8-10,1.8s-7-0.6-10-1.8
	s-5.6-2.9-7.7-5.1s-3.8-4.7-5-7.7c-1.2-2.9-1.8-6.1-1.8-9.5s0.6-6.6,1.8-9.5s2.8-5.5,5-7.6c2.1-2.2,4.7-3.9,7.7-5.1s6.4-1.8,10-1.8
	c3.7,0,7,0.6,10,1.9c3,1.2,5.6,2.9,7.7,5.1s3.8,4.7,5,7.6S161.9,35.1,161.9,38.5z M150.7,38.5c0-2.3-0.3-4.4-0.9-6.3
	s-1.5-3.4-2.6-4.7s-2.5-2.3-4.2-3c-1.6-0.7-3.5-1-5.6-1s-4,0.3-5.6,1s-3,1.7-4.2,3c-1.1,1.3-2,2.9-2.6,4.7c-0.6,1.9-0.9,4-0.9,6.3
	s0.3,4.5,0.9,6.3c0.6,1.9,1.5,3.4,2.6,4.7c1.1,1.3,2.5,2.3,4.2,3c1.6,0.7,3.5,1,5.6,1s4-0.3,5.6-1s3-1.7,4.2-3
	c1.1-1.3,2-2.9,2.6-4.7S150.7,40.9,150.7,38.5z"
        />
        <path
          d="M195.4,24.5c-0.3,0.5-0.7,0.9-1,1.2c-0.4,0.3-0.8,0.4-1.4,0.4c-0.5,0-1-0.2-1.6-0.5s-1.2-0.7-1.9-1.1
	s-1.5-0.7-2.5-1.1s-2-0.5-3.2-0.5c-2,0-3.6,0.4-4.6,1.3s-1.5,2.1-1.5,3.5c0,0.9,0.3,1.7,0.9,2.4c0.6,0.6,1.4,1.2,2.4,1.6
	c1,0.5,2.1,0.9,3.4,1.2c1.3,0.4,2.6,0.8,3.9,1.3s2.6,1,3.9,1.6s2.4,1.4,3.4,2.4s1.8,2.2,2.4,3.5c0.6,1.4,0.9,3.1,0.9,5
	c0,2.2-0.4,4.2-1.1,6.1c-0.8,1.9-1.8,3.6-3.3,5c-1.4,1.4-3.2,2.5-5.3,3.3s-4.5,1.2-7.2,1.2c-1.5,0-3-0.2-4.5-0.5s-3-0.7-4.4-1.3
	s-2.8-1.2-4-2s-2.3-1.6-3.3-2.5l3.2-5.1c0.3-0.4,0.6-0.7,1-0.9s0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,2,0.6s1.4,0.9,2.2,1.4
	s1.8,0.9,2.9,1.4c1.1,0.4,2.3,0.6,3.8,0.6c2,0,3.5-0.4,4.6-1.3s1.6-2.3,1.6-4.2c0-1.1-0.3-2-0.9-2.7s-1.4-1.3-2.4-1.7
	c-1-0.5-2.1-0.9-3.3-1.2c-1.3-0.3-2.5-0.7-3.8-1.1s-2.6-0.9-3.8-1.6c-1.3-0.6-2.4-1.4-3.3-2.4s-1.8-2.3-2.4-3.8s-0.9-3.3-0.9-5.5
	c0-1.8,0.4-3.5,1.1-5.2s1.8-3.2,3.1-4.5c1.4-1.3,3.1-2.4,5.1-3.2s4.3-1.2,6.9-1.2c1.4,0,2.9,0.1,4.2,0.3c1.4,0.2,2.7,0.6,3.9,1
	s2.4,1,3.4,1.6c1.1,0.6,2,1.3,2.9,2.1L195.4,24.5L195.4,24.5z"
        />
        <path
          d="M238.2,61.8c-2.2-2.3-3.9-5.1-5.3-8.1h-16.8V42.5H230c-0.1-1.3-0.2-2.6-0.2-3.9c0-1.4,0.1-2.8,0.2-4.1h-13.8
	v-11h16.9c1.5-3.1,3.4-5.9,5.7-8.4l0.1-0.1h-33.7v47.1h33.4C238.5,62,238.3,61.9,238.2,61.8L238.2,61.8z"
        />
        <path
          d="M273,49.9c0.3,0,0.5,0,0.8,0.1s0.5,0.3,0.7,0.5l4.3,4.6c-1.9,2.5-4.3,4.4-7.1,5.6c-2.9,1.3-6.2,1.9-10.1,1.9
	c-3.6,0-6.8-0.6-9.6-1.8s-5.2-2.9-7.2-5s-3.5-4.7-4.5-7.6c-1.1-2.9-1.6-6.1-1.6-9.6s0.6-6.7,1.7-9.7c1.1-2.9,2.7-5.5,4.8-7.6
	s4.6-3.8,7.4-5c2.9-1.2,6.1-1.8,9.5-1.8c1.8,0,3.4,0.2,5,0.5s3,0.7,4.4,1.3s2.6,1.2,3.8,2s2.2,1.6,3,2.5l-3.7,4.9
	c-0.2,0.3-0.5,0.6-0.8,0.8s-0.8,0.4-1.4,0.4c-0.4,0-0.8-0.1-1.1-0.3s-0.7-0.4-1.1-0.6s-0.8-0.5-1.3-0.8s-1-0.6-1.6-0.8
	s-1.4-0.5-2.2-0.6c-0.9-0.2-1.8-0.3-3-0.3c-1.8,0-3.5,0.3-5,1s-2.8,1.6-3.9,2.9s-1.9,2.9-2.5,4.7c-0.6,1.9-0.9,4-0.9,6.4
	s0.3,4.6,1,6.4c0.7,1.9,1.6,3.5,2.7,4.7c1.1,1.3,2.5,2.3,4,2.9c1.5,0.7,3.1,1,4.9,1c1,0,1.9,0,2.7-0.1s1.6-0.3,2.3-0.5
	s1.4-0.5,2-0.9s1.3-0.9,1.9-1.4c0.3-0.2,0.5-0.4,0.8-0.5S272.7,49.9,273,49.9L273,49.9z"
        />
        <path
          d="M331.7,50.2c0,4.1-1.5,7.3-4.4,9.6s-7,3.5-12.3,3.5c-4.8,0-9.1-0.9-12.8-2.7v-8.9c3,1.4,5.6,2.3,7.7,2.9
	s4,0.8,5.8,0.8c2.1,0,3.7-0.4,4.8-1.2s1.7-2,1.7-3.6c0-0.9-0.2-1.7-0.7-2.4s-1.2-1.4-2.2-2s-2.9-1.7-5.9-3.1
	c-2.8-1.3-4.8-2.5-6.2-3.7s-2.5-2.6-3.3-4.2s-1.2-3.4-1.2-5.6c0-4,1.4-7.1,4.1-9.4s6.5-3.4,11.2-3.4c2.3,0,4.6,0.3,6.7,0.8
	c2.1,0.6,4.4,1.3,6.7,2.3l-3.1,7.5c-2.4-1-4.4-1.7-6-2.1s-3.1-0.6-4.7-0.6c-1.8,0-3.2,0.4-4.2,1.3c-1,0.8-1.5,1.9-1.5,3.3
	c0,0.8,0.2,1.6,0.6,2.2s1,1.2,1.9,1.8s2.9,1.6,6.1,3.2c4.2,2,7.1,4,8.7,6.1C331,44.7,331.7,47.2,331.7,50.2L331.7,50.2z"
        />
        <path
          d="M375.5,19.2c0-3.1,1.7-4.6,5.1-4.6s5.1,1.5,5.1,4.6c0,1.5-0.4,2.6-1.3,3.4s-2.1,1.2-3.8,1.2
	C377.2,23.9,375.5,22.3,375.5,19.2L375.5,19.2z M385.4,62.7H376V28.2h9.4V62.7z"
        />
        <path d="M402.1,62.7h-9.4V14.6h9.4V62.7z" />
        <path d="M418.9,62.7h-9.4V14.6h9.4V62.7z" />
        <path
          d="M451.7,52.5c0,3.5-1.2,6.2-3.7,8.1s-6.1,2.8-11.1,2.8c-2.5,0-4.7-0.2-6.4-0.5c-1.8-0.3-3.4-0.8-5-1.5v-7.8
	c1.8,0.8,3.7,1.5,5.9,2.1s4.1,0.8,5.8,0.8c3.4,0,5.1-1,5.1-3c0-0.7-0.2-1.3-0.7-1.8s-1.2-1-2.3-1.6s-2.6-1.3-4.5-2.1
	c-2.7-1.1-4.6-2.1-5.9-3.1c-1.2-0.9-2.2-2-2.7-3.3c-0.6-1.2-0.8-2.7-0.8-4.5c0-3.1,1.2-5.4,3.6-7.1s5.8-2.5,10.1-2.5
	c4.2,0,8.2,0.9,12.2,2.7l-2.8,6.8c-1.7-0.7-3.3-1.4-4.9-1.8s-3-0.7-4.6-0.7c-2.8,0-4.2,0.8-4.2,2.3c0,0.8,0.4,1.6,1.3,2.2
	s2.9,1.5,5.9,2.8c2.7,1.1,4.7,2.1,5.9,3.1c1.3,0.9,2.2,2,2.8,3.3C451.4,49.3,451.7,50.8,451.7,52.5L451.7,52.5z"
        />
        <polygon points="358.3,41.6 368.6,26.9 356.7,26.9 352.3,33.1 " />
        <polygon points="347.4,32.5 347.4,14.6 337.9,14.6 337.9,62.7 347.4,62.7 347.4,49.5 356.7,62.7 368.6,62.7 " />
        <path d="M451.2,62.7h-0.3v-2.6H450v-0.3h2.2v0.3h-0.9v2.6H451.2L451.2,62.7z" />
        <path d="M453.8,62.7l-1-2.6v0.7v1.9h-0.3v-2.9h0.5l0.9,2.4l0.9-2.4h0.5v2.9H455v-1.9v-0.7l-1,2.6H453.8z" />
      </g>
    </svg>
  );
}

export function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 559.4 106.4"
      {...props}
    >
      <g style={{ fill: '#fff' }}>
        <path d="M27,100.4H5V6h22V100.4z" />
        <path
          d="M127.6,6v94.3h-11.4c-1.7,0-3.1-0.3-4.2-0.8c-1.1-0.5-2.3-1.5-3.4-2.8L64.1,40.5c0.2,1.7,0.3,3.3,0.4,4.9
		c0.1,1.6,0.1,3.1,0.1,4.4v50.6H45.3V6h11.5c0.9,0,1.7,0,2.4,0.1c0.6,0.1,1.2,0.2,1.7,0.5c0.5,0.2,1,0.6,1.5,1c0.5,0.4,1,1,1.6,1.7
		L108.9,66c-0.2-1.8-0.4-3.6-0.5-5.3c-0.1-1.7-0.1-3.3-0.1-4.8V6H127.6z"
        />
        <path
          d="M195.1,53.2c0-2.2,0.1-4.3,0.3-6.4h-27.6V22.9h34.2c3-6.2,7-11.7,11.7-16.6c0.1-0.1,0.2-0.2,0.3-0.3h-68.3
		v94.3h22V63.6H196C195.5,60.2,195.1,56.7,195.1,53.2z"
        />
        <path
          d="M309,53.2c0,6.8-1.2,13.2-3.5,19c-2.4,5.9-5.7,11-9.9,15.3c-4.3,4.3-9.4,7.7-15.4,10.2c-6,2.5-12.7,3.7-20,3.7
		c-7.3,0-14-1.2-20.1-3.7c-6-2.5-11.2-5.9-15.5-10.2c-4.3-4.3-7.6-9.4-10-15.3c-2.4-5.9-3.5-12.2-3.5-19c0-6.8,1.2-13.2,3.5-19
		c2.4-5.9,5.7-11,10-15.3c4.3-4.3,9.5-7.7,15.5-10.2C246,6.2,252.7,5,260,5c7.3,0,14,1.2,20,3.7c6,2.5,11.2,5.9,15.4,10.2
		c4.3,4.3,7.6,9.4,9.9,15.3C307.8,40.1,309,46.4,309,53.2z M286.6,53.2c0-4.7-0.6-8.9-1.8-12.6c-1.2-3.7-2.9-6.9-5.2-9.5
		c-2.3-2.6-5-4.6-8.3-6c-3.3-1.4-7-2.1-11.2-2.1c-4.2,0-8,0.7-11.3,2.1c-3.3,1.4-6.1,3.4-8.3,6c-2.3,2.6-4,5.8-5.2,9.5
		c-1.2,3.7-1.8,7.9-1.8,12.6c0,4.7,0.6,8.9,1.8,12.7c1.2,3.7,2.9,6.9,5.2,9.5c2.3,2.6,5,4.6,8.3,5.9c3.3,1.4,7.1,2,11.3,2
		c4.2,0,7.9-0.7,11.2-2c3.3-1.4,6.1-3.3,8.3-5.9c2.3-2.6,4-5.7,5.2-9.5C286,62.1,286.6,57.9,286.6,53.2z"
        />
        <path
          d="M375.9,25.2c-0.6,1-1.3,1.8-2,2.3c-0.7,0.5-1.6,0.8-2.8,0.8c-1,0-2.1-0.3-3.2-0.9s-2.4-1.3-3.9-2.1
		c-1.4-0.8-3.1-1.5-5-2.1c-1.9-0.6-4-0.9-6.3-0.9c-4.1,0-7.2,0.9-9.2,2.6c-2,1.7-3,4.1-3,7.1c0,1.9,0.6,3.5,1.8,4.7
		c1.2,1.3,2.8,2.3,4.8,3.2c2,0.9,4.2,1.7,6.7,2.5c2.5,0.8,5.1,1.6,7.7,2.5c2.6,0.9,5.2,2,7.7,3.3c2.5,1.3,4.8,2.8,6.7,4.8
		c2,1.9,3.5,4.3,4.8,7.1c1.2,2.8,1.8,6.1,1.8,10.1c0,4.4-0.8,8.4-2.3,12.2c-1.5,3.8-3.7,7.1-6.6,9.9c-2.9,2.8-6.4,5.1-10.6,6.7
		c-4.2,1.6-9,2.4-14.3,2.4c-2.9,0-5.9-0.3-9-0.9c-3-0.6-6-1.5-8.8-2.6s-5.5-2.4-8-3.9c-2.5-1.5-4.7-3.2-6.5-5.1l6.5-10.2
		c0.5-0.7,1.2-1.3,2-1.8c0.8-0.5,1.8-0.7,2.8-0.7c1.3,0,2.6,0.4,3.9,1.2c1.3,0.8,2.8,1.7,4.5,2.7c1.7,1,3.6,1.9,5.7,2.7
		c2.2,0.8,4.7,1.2,7.6,1.2c4,0,7.1-0.9,9.3-2.6c2.2-1.7,3.3-4.5,3.3-8.3c0-2.2-0.6-4-1.8-5.4c-1.2-1.4-2.8-2.5-4.8-3.4
		c-2-0.9-4.2-1.7-6.7-2.4c-2.5-0.7-5.1-1.5-7.7-2.3c-2.6-0.8-5.2-1.9-7.7-3.1c-2.5-1.2-4.7-2.8-6.7-4.9c-2-2-3.5-4.5-4.8-7.5
		c-1.2-3-1.8-6.7-1.8-11.1c0-3.5,0.7-7,2.1-10.4c1.4-3.4,3.5-6.4,6.3-9c2.8-2.6,6.1-4.7,10.2-6.3c4-1.6,8.6-2.4,13.8-2.4
		c2.9,0,5.7,0.2,8.4,0.7c2.7,0.5,5.3,1.1,7.8,2c2.5,0.9,4.8,1.9,6.9,3.2c2.1,1.2,4,2.6,5.7,4.2L375.9,25.2z"
        />
        <path
          d="M461.7,99.7c-4.3-4.7-7.9-10.1-10.6-16.1h-33.6V61.1h27.6c-0.3-2.6-0.4-5.2-0.4-7.9c0-2.8,0.2-5.6,0.5-8.3
		h-27.7v-22h33.8c3-6.3,6.8-11.9,11.5-16.7c0,0,0.1-0.1,0.1-0.1h-67.4v94.3h66.9C462.2,100.1,461.9,99.9,461.7,99.7z"
        />
        <path
          d="M531.3,75.9c0.5,0,1,0.1,1.6,0.3c0.5,0.2,1,0.5,1.5,1l8.7,9.1c-3.8,5-8.6,8.7-14.3,11.2
		c-5.7,2.5-12.5,3.8-20.3,3.8c-7.2,0-13.6-1.2-19.3-3.7s-10.5-5.8-14.4-10.1c-4-4.3-7-9.4-9.1-15.3c-2.1-5.9-3.2-12.3-3.2-19.2
		c0-7,1.1-13.5,3.4-19.4c2.3-5.9,5.5-11,9.6-15.2c4.1-4.3,9.1-7.6,14.9-10c5.8-2.4,12.1-3.6,19.1-3.6c3.5,0,6.9,0.3,10,0.9
		c3.1,0.6,6.1,1.5,8.8,2.6c2.7,1.1,5.2,2.4,7.5,4c2.3,1.6,4.3,3.2,6.1,5.1l-7.4,9.9c-0.5,0.6-1,1.2-1.7,1.7
		c-0.6,0.5-1.6,0.7-2.7,0.7c-0.8,0-1.5-0.2-2.2-0.5c-0.7-0.3-1.4-0.8-2.2-1.3c-0.8-0.5-1.6-1-2.6-1.6c-0.9-0.6-2-1.1-3.3-1.6
		c-1.3-0.5-2.8-0.9-4.5-1.3c-1.7-0.3-3.7-0.5-6-0.5c-3.6,0-6.9,0.7-9.9,2c-3,1.3-5.6,3.3-7.8,5.9c-2.2,2.6-3.9,5.7-5.1,9.5
		s-1.8,8-1.8,12.8c0,4.8,0.7,9.1,2,12.9c1.3,3.8,3.1,6.9,5.4,9.5c2.3,2.6,4.9,4.5,7.9,5.9c3,1.3,6.3,2,9.7,2c2,0,3.8-0.1,5.4-0.3
		c1.6-0.2,3.2-0.5,4.6-1c1.4-0.5,2.8-1.1,4-1.8c1.3-0.8,2.5-1.7,3.8-2.9c0.5-0.4,1.1-0.8,1.7-1.1C530.1,76.1,530.7,75.9,531.3,75.9z
		"
        />
      </g>
      <g>
        <path d="M544.4,101.4h-0.8v-6.2h-2.2v-0.7h5.2v0.7h-2.2V101.4z" />
        <path
          d="M550.6,101.4l-2.3-6.1h0c0,0.5,0.1,1.1,0.1,1.7v4.4h-0.7v-6.9h1.2l2.2,5.7h0l2.2-5.7h1.2v6.9h-0.8v-4.5
		c0-0.5,0-1.1,0.1-1.7h0l-2.4,6.1H550.6z"
        />
      </g>
    </svg>
  );
}
